<template>
  <div>
    <div class="tasksArea ">
      <v-fade-transition mode="out-in">
        <div v-if="exercises.length != 0" :key="1">
          <template v-for="(exercise , i) in exercises">
            <TaskCard :exercise="exercise" :key="i"/>
          </template>
        </div>
        <div v-else :key="2">
          <div class="text-center mt-5">
            <img src="../../assets/image/svg/tasks/emptyState.svg" alt="">
            <div class="titleEmptyState">{{$t('tasks.empty.title')}}</div>
            <div class="subtitleEmptyState mt-2">
              {{$t('tasks.empty.text')}}
            </div>
          </div>
        </div>
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
import TaskCard from "@/components/Task/TaskCard";

export default {
  components: {TaskCard},
  data() {
    return {
      exercises: [],
    }
  },
  methods: {
    getTasks() {
      this.$emitEvent('setLoading', true)
      this.requesting('exercise', 'getExercise').then((resp) => {
        this.exercises = resp.data.exercises
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    }
  },
  mounted() {
    this.getTasks()
  }
}
</script>

<style scoped>
.tasksArea {
  width: 90%;
  margin: 50px auto;
}

.titleEmptyState {
  font-size: 21px;
}

.subtitleEmptyState {
  font-size: 14px;
  font-weight: 500;
  color: rgb(109, 109, 109);
}
</style>