<template>
  <div class="my-5">
    <div class="TaskCard d-flex flex-column ">
      <div class="d-flex justify-space-between">
        <div class="titleTasks mt-4">
          {{ exercise.title }}
        </div>
        <div>
          <!--          <v-progress-circular value="100" size="50" color="#4576d8" class="en_num">-->
          <!--            100%-->
          <!--          </v-progress-circular>-->
        </div>
      </div>
      <div class="d-flex justify-space-between flex-column "
           :class="$vuetify.breakpoint.mdAndDown && 'flex-wrap justify-center'">
        <div class="tasksData d-flex mt-4 ">
          {{ exercise.description }}
          <!--          <div>-->
          <!--            تعداد سوالات 21-->
          <!--          </div>-->
          <!--          <div class="mr-4">-->
          <!--            تعداد سوالات 21-->
          <!--          </div>-->
        </div>
        <div class="d-flex mt-4 font-weight-bold justify-space-between">
          <div class="tasksData">
            {{ exercise.doctor.user.full_name }}
          </div>
          <div>
            <template v-if="exercise.document">
              <a :href="`https://api.simiaroom.com/${exercise.document}`" target="_blank" style="text-decoration: none">
                <v-btn class="startBtn" small elevation="0">
                  {{ $t('download') }}
                </v-btn>
              </a>
            </template>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    exercise: Object,
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .startBtn {
    margin: 10px auto 0px auto !important;
  }

  .TaskCard {
    height: 160px !important;
  }
}

.TaskCard {
  height: 150px;
  background: url("../../assets/image/svg/tasks/tasksBG.svg") no-repeat center;
  background-size: cover;
  border: 1px solid #1c53c1;
  border-radius: 12px;
  padding: 20px 30px;
}

.titleTasks {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: right;
  color: #1c53c1;

}

.tasksData {
  font-size: 12px;
  color: #1c53c1;
}

.startBtn {
  width: 110px;
  height: 35px;
  border-radius: 10px;
  background-color: #1c53c1 !important;
  color: white;
}
</style>